/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogCard from "../components/project-card"

export const pageQuery = graphql`
  query tagsProjectQuery($id: String!, $tagTitle: String) {
    allMarkdownRemark(filter: { frontmatter: { tags: { eq: $tagTitle } } }) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            authors
            description
            align
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 848, quality: 80) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
                sizes {
                  src
                }
              }
            }
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        slug
      }
    }
  }
`

const Tags = ({ data }) => {
  const { markdownRemark, allMarkdownRemark } = data // data.markdownRemark holds your tags data
  const { frontmatter, html } = markdownRemark

  const eachTagCard = allMarkdownRemark.edges
    .filter(edge => !!edge.node.frontmatter.date)
    .map(edge => {
      edge.node.align = 'center'; 
      return <BlogCard key={edge.node.id} data={edge.node} />
    })

  return (
    <Layout>
      <SEO title={frontmatter.title} article={true} />
      <div sx={tagsStyles.tagPageContainer}>
        <div sx={tagsStyles.tagContainer}>
          <header>
            {frontmatter.title ? (
              <section sx={tagsStyles.pageHead}>
                <h1>{frontmatter.title}</h1>
                <p>{eachTagCard.length} Projects</p>
              </section>
            ) : (
              "No Tags"
            )}
            <div sx={tagsStyles.eachTagCard}>{eachTagCard}</div>
          </header>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </div>
    </Layout>
  )
}

export default Tags

const tagsStyles = {
  tagPageContainer: {
    variant: "variants.section",
  },
  tagContainer: {
    variant: "variants.container",
    minHeight: "70vh",
  },
  eachTagCard: {
    display: "grid",
    gridTemplateColumns: [
      "repeat(1, 1fr)",
    ],
    gap: "30px",
  },
  pageHead: {
    variant: "variants.pageHead",
  },
}
